(function ($) {

  "use strict";

  $(window).on('load', function () {
    $('#preloader').fadeOut();

    $(window).on('scroll', function () {
      if ($(window).scrollTop() > 50) {
        $('.scrolling-navbar').addClass('top-nav-collapse');
      } else {
        $('.scrolling-navbar').removeClass('top-nav-collapse');
      }
    });

    $('.navbar-nav').onePageNav({
      currentClass: 'active'
    });

    $('.mobile-menu').slicknav({
      prependTo: '.navbar-header',
      parentTag: 'liner',
      allowParentLinks: true,
      duplicate: true,
      label: '',
      closedSymbol: '<i class="fa fa-chevron-right"></i>',
      openedSymbol: '<i class="fa fa-chevron-down"></i>',
    });

    $('#portfolio').mixItUp();

    $('#single-testimonial-item').owlCarousel({
      items: 1,
      navigation: true,
      pagination: false,
      slideSpeed: 300,
      paginationSpeed: 400,
      singleItem: true
    });

    $('#single-testimonial-item').find('.owl-prev').html('<i class="fa fa-angle-left"></i>');
    $('#single-testimonial-item').find('.owl-next').html('<i class="fa fa-angle-right"></i>');

    $('.timer').countTo({
      refreshInterval: 60,
      formatter: function (value, options) {
        return value.toFixed(options.decimals);
      },
    });

    var offset = 200;
    var duration = 500;
    $(window).scroll(function () {
      if ($(this).scrollTop() > offset) {
        $('.back-to-top').fadeIn(400);
      } else {
        $('.back-to-top').fadeOut(400);
      }
    });

    $('.back-to-top').on('click', function (event) {
      event.preventDefault();
      $('html, body').animate({
        scrollTop: 0
      }, 600);
      return false;
    });

  });

}(jQuery));